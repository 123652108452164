import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 5×2\\@90% 1RM`}</p>
    <p>{`Stiff Leg Deadlifts 5×2`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`25-Thrusters (95/65) (rx+ 115/75)`}</p>
    <p>{`35-Box Jumps (24/20)`}</p>
    <p>{`45-Wall Balls (20/14) (rx+ 30/20)`}</p>
    <p>{`55-Calorie Assault Bike`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      